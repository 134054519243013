<template>
  <v-dialog
    v-model="isVisible"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ isEditMode? "Deploy ": "Add New " }} Messaging Service</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="field.name"
                label="Sender Name *"
                required
                :error="!(field.name && field.name.length) && isFormDirty"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-if="isEditMode"
              cols="12"
            >
              <v-text-field
                v-model="field.number"
                required
                disabled="true"
                label="Sender Number *"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="field.signature"
                label="Signature"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="field.inviteCode"
                label="Invite Code"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-textarea
                v-model="field.welcomeMessage"
                label="Welcome Message"
              />
            </v-col>
          </v-row>
          <small>* indicates required field</small>
          <v-row>
            <v-col>
              <div class="phoneNumbersWrapper">
                <h5>Manage Phone Numbers</h5>
                <div
                  v-for="number in numbers"
                  :key="number.PhoneNumber"
                  @click="updatePhoneNumber(number)"
                >
                  <v-checkbox
                    v-model="number.isSelected"
                    class="numberCheckBox"
                    :label="number.PhoneNumber"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="submit"
        >
          {{ isEditMode? "Deploy": "Add" }}
        </v-btn>
      </v-card-actions>
      <v-snackbar
        v-model="snackbar"
      >
        {{ notification }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'
  import { VueMaskDirective } from 'v-mask'
  import Vue from 'vue'
  Vue.directive('mask', VueMaskDirective)

  export default {
    name: 'MessagingServiceAddEditDialog',
    props: {
      isVisible: {
        type: Boolean,
        default: false,
      },
      isEditMode: {
        type: Boolean,
        default: false,
      },
      selectedMessagingService: {
        type: Object,
        default: null,
      },
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      username: null,
      snackbar: false,
      field: {
        name: '',
        number: '',
        signature: '',
        inviteCode: '',
        welcomeMessage: '',
      },
      isFormDirty: false,
      numbers: [],
      numbersToAdd: [],
      numbersToDelete: [],
    }),

    watch: {
      isVisible (val) {
        if (val) {
          this.isFormDirty = false
          // Dialog opened
          if (this.isEditMode) {
            // Opening in edit mode
            // Assign exisiting entry values
            if (this.selectedMessagingService.MSSID && this.selectedMessagingService.MSSID.length) {
              this.field.number = this.selectedMessagingService.MSSID
            }
            if (this.selectedMessagingService.MSName && this.selectedMessagingService.MSName.length) {
              this.field.name = this.selectedMessagingService.MSName
            }
            if (this.selectedMessagingService.Signiture && this.selectedMessagingService.Signiture.length) {
              this.field.signature = this.selectedMessagingService.Signiture
            }
            if (this.selectedMessagingService.InviteCode && this.selectedMessagingService.InviteCode.length) {
              this.field.InviteCode = this.selectedMessagingService.InviteCode
            }
            if (this.selectedMessagingService.welcomeMessage && this.selectedMessagingService.welcomeMessage.length) {
              this.field.WelcomeMessage = this.selectedMessagingService.welcomeMessage
            }
            this._prepareNumbers('editMode')
          } else {
            // Opening in add mode
            // Reset all fields
            this.field = Object.assign({
              name: '',
              number: '',
              signature: '',
              inviteCode: '',
              welcomeMessage: '',
            })
            this._prepareNumbers('addMode')
          }
        } else {
        }
      },
    },

    created () {
      this.username = localStorage.getItem('username')
    },

    methods: {
      async initialize () {
      },

      async _prepareNumbers (mode) {
        if (mode === 'editMode') {
        // Edit mode
        } else {
          // Add mode
          this.$emit('show-loader')
          // Get all numbers
          try {
            const { data: allNumbers } = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}smssendphonenumbers?code=${this.apiCode}`)
            // Filter out non deployed numbers
            const nonDeployedNumbers = allNumbers.filter(no => no.isDeployed === false)
            this.numbers = nonDeployedNumbers.map(no => ({
              ...no,
              isSelected: false,
            }))
            this.$emit('hide-loader')
          } catch (error) {}
        }
      },

      close () {
        this.$emit('on-close')
      },

      isFormValid () {
        this.notification = ''
        if (!this.field.name || !this.field.name.length) {
          this.notification = 'Fill all the required fields'
          return false
        }
        return true
      },

      updatePhoneNumber (phoneNumber) {
        if (phoneNumber.isSelected) {
          // Just selected
          // First select if recently deleted
          const indexInDeleteArray = this.numbersToDelete.findIndex(no => no.PhoneNumber === phoneNumber.PhoneNumber)
          if (indexInDeleteArray > -1) {
            this.numbersToDelete.splice(indexInDeleteArray, 1)
          } else {
            this.numbersToAdd.push(phoneNumber)
          }
        } else {
          // Just unselected
          // First select if recently deleted
          const indexInAddArray = this.numbersToAdd.findIndex(no => no.PhoneNumber === phoneNumber.PhoneNumber)
          if (indexInAddArray > -1) {
            this.numbersToAdd.splice(indexInAddArray, 1)
          } else {
            this.numbersToDelete.push(phoneNumber)
          }
        }
      },

      _generateCommaSeparatedString (sourceArray, propertyKey) {
        let resultString = ''
        const sourceArrayLength = sourceArray.length
        sourceArray.forEach((el, index) => {
          resultString += el[propertyKey]
          if (index !== sourceArrayLength - 1) {
            // Not Last element, append comma
            resultString += ','
          }
        })
        return resultString
      },

      submit () {
        this.isFormDirty = true
        if (!this.isFormValid()) {
          this.snackbar = true
          return
        }
        if (this.isEditMode) {
          // Update
          const payload = {
            SenderID: 0, // only for update
            SendNumber: this.field.number,
            SenderName: this.field.name,
            Username: this.username,
          }
          if (!this.selectedMessagingService.MSSID) {
            this.notification = 'Error: No Sender Id'
            this.snackbar = true
          } else {
            if (this.field.signature && this.field.signature.length) {
              payload.Signiture = this.field.signature
            }
            if (this.field.welcomeMessage && this.field.welcomeMessage.length) {
              payload.WelcomeMessage = this.field.welcomeMessage
            }
            if (this.field.inviteCode && this.field.inviteCode.length) {
              payload.InviteCode = this.field.inviteCode
            }
            this.$emit('on-submit')
            this.httpConnector.makeRequest('post', `${this.apiEndpoint}messagingservice?code=${this.apiCode}`, payload)
              .then((res) => {
                this.$emit('on-success', res)
                this.close()
              })
              .catch((error) => {
                this.$emit('on-error', error)
              })
          }
        } else {
          // Add
          const payload = {
            SenderName: this.field.name,
            Username: this.username,
            SenderTypeID: 1,
            PhoneNumbers: this._generateCommaSeparatedString(this.numbersToAdd, 'SID'),
          }
          if (this.field.signature && this.field.signature.length) {
            payload.Signiture = this.field.signature
          }
          if (this.field.welcomeMessage && this.field.welcomeMessage.length) {
            payload.WelcomeMessage = this.field.welcomeMessage
          }
          if (this.field.inviteCode && this.field.inviteCode.length) {
            payload.InviteCode = this.field.inviteCode
          }
          this.$emit('on-submit')
          this.httpConnector.makeRequest('post', `${this.apiEndpoint}messagingservice?code=${this.apiCode}`, payload)
            .then((res) => {
              this.$emit('on-success', res)
              this.close()
            })
            .catch((error) => {
              this.$emit('on-error', error)
            })
        }
      },
    },
  }
</script>
<style>
.phoneNumbersWrapper {
  margin-top: 4rem;
}
.numberCheckBox label {
  color: black!important;
}
</style>
